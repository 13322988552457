import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from 'react-bootstrap';
import { useEditCustomerMutation } from "../api/customersApi";
import { Loader } from "../../../../components";
import { customerFormValidation } from "../../../../utils/formValidation";
import { useListCompanyMutation } from "../../../admin/company/api/companyApi";
import XCircleFillIcon from '../../../../assets/icons/XCircleFill';
import Select from 'react-select';

const EditCustomer = ({ selectedRow, onClose, paginationState, setToastMessage }) => {
    const { id, name, email, phone, company_id, companies } = selectedRow;
    const [formData, setFormData] = useState({ name, email, phone, company_id });
    const [errorMessage, setErrorMessage] = useState('');
    const [validationError, setValidationError] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [companiesList, setCompaniesList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState({ value: company_id, label: companies?.name });
    const [editCustomerApi] = useEditCustomerMutation();
    const [listCompanyApi] = useListCompanyMutation();

    useEffect(() => {
        fetchCompanyList();
    }, []);

    const fetchCompanyList = async () => {
        try {
            setShowLoader(true);
            const companies = await listCompanyApi({}).unwrap();
            const companiesData = companies?.data;
            if (companiesData?.companies?.length) {
                const companiesArrList = companiesData.companies.map(cp => ({ value: cp.id, label: cp.name }));
                companiesArrList.sort((a, b) => a.label.localeCompare(b.label));
                setCompaniesList(companiesArrList);
            }
        } catch (error) {
            setErrorMessage(error?.data?.message || error?.data?.error || "Failed to fetch list of companies");
        }
        setShowLoader(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        const validationResult = customerFormValidation({ ...formData, [name]: value });
        if (!validationResult.errorMessage?.[name] && validationError[name]) {
            setErrorMessage('');
            setValidationError({ ...validationError, [name]: "" });
            return;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            formData.company_id = selectedCompany?.value;
            const validationResult = customerFormValidation(formData);
            if (validationResult.errorMessage) {
                setErrorMessage('');
                setValidationError(validationResult.errorMessage);
                return;
            }

            setShowLoader(true);
            await editCustomerApi({ ...validationResult.data, id }).unwrap();
            paginationState.setPagination({
                ...paginationState.pagination,
            });
            setToastMessage({
                message: "Customer updated successfully",
                variant: "success"
            });
            onClose();
        } catch (err) {
            setValidationError({});
            setErrorMessage(err?.data?.message || err?.data?.error || 'Failed to edit this customer');
            setShowLoader(false);
        }
    };

    return (
        <>
            <Modal show={true} onHide={onClose}>
                {showLoader && <Loader />}
                <Modal.Header>
                    <Modal.Title className='title'>Edit Customer</Modal.Title>
                    <Button variant="basic" className='ms-auto px-0' onClick={onClose}><XCircleFillIcon size={24} /></Button>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        {errorMessage && <p className="error-message fs-6">{errorMessage}</p>}
                        <Form.Group className="mb-3" controlId="company">
                            <Form.Label>Select Company<span className='input-required-icn'>*</span></Form.Label>
                            <Select
                                options={companiesList}
                                value={selectedCompany}
                                onChange={(selectedOption) => setSelectedCompany(selectedOption)}
                                className="react-select-container"
                                classNamePrefix="react-select"
                            />
                            {validationError.id && <p className="error-message position-absolute">{validationError.id}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name<span className='input-required-icn'>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Enter name"
                                value={formData.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {validationError.name && <p className="error-message position-absolute">{validationError.name}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email Address<span className='input-required-icn'>*</span></Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder="Enter email"
                                value={formData.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {validationError.email && <p className="error-message position-absolute">{validationError.email}</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="phone">
                            <Form.Label>Phone Number<span className='input-required-icn'>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="phone"
                                placeholder="Enter phone number"
                                value={formData.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {validationError.phone && <p className="error-message position-absolute">{validationError.phone}</p>}
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer className='border-0 pt-0'>
                        <Button variant="-primary" type='submit'>
                            Save
                        </Button>
                        <Button variant="primary-outline" onClick={onClose}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default EditCustomer;
