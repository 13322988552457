import { chartLabelDateOptions, chartDatasetsOptions } from "./constants";
import ProcessIcon from "../assets/icons/Process";
import CheckMarkIcon from "../assets/icons/CheckMark";
import BanIcon from "../assets/icons/Ban";
import HourglassIcon from "../assets/icons/Hourglass";

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const dateOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',

    };

    const timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    }

    return `${date.toLocaleDateString('en-US', dateOptions)} | ${date.toLocaleTimeString('en-US', timeOptions)}`;
};

export const defaultPreviousWeekDates = () => {
    try {
        const todaysDay = new Date().getDay();
        const todaysDate = new Date().getDate();
        const previousSunday = new Date(new Date().setDate(todaysDate - (todaysDay + 7)));
        const previousSaturday = new Date(new Date().setDate(todaysDate - (todaysDay + 1)));

        return {
            startDate: previousSunday,
            endDate: previousSaturday
        }
    } catch (error) {
        return {
            startDate: new Date(),
            endDate: new Date()
        }
    }
}

export const formatChartInputData = (data) => {
    try {
        const labels = [];
        const dataSets = [
            // Payable Data
            [],
            // Profit Data
            [],
            // Revenue Data
            []
        ];
        for (const i of data) {
            const startDate = stringToDate(i.from_date).toLocaleDateString('en-GB', chartLabelDateOptions);
            const endDate = stringToDate(i.to_date).toLocaleDateString('en-GB', chartLabelDateOptions);
            labels.push([startDate, "-", endDate]);

            // Payable Data
            if (i.payable !== undefined) {
                dataSets[0].push(i.payable);
            }
            // Profit Data
            dataSets[1].push(i.profit);
            // Revenue Data
            dataSets[2].push(i.revenue);
        }
        const dataToReturn = {
            labels,
            datasets: [
                {
                    label: 'Profit',
                    backgroundColor: 'rgba(144, 190, 109, 1)',
                    data: dataSets[1],
                    ...chartDatasetsOptions
                },
                {
                    label: 'Total revenue',
                    backgroundColor: 'rgba(88, 86, 214, 1)',
                    data: dataSets[2],
                    ...chartDatasetsOptions
                }
            ]
        }
        // Admin dashboard has no payable data to show
        if (dataSets[0]?.length) {
            dataToReturn.datasets.unshift({
                label: 'Total Payable',
                backgroundColor: 'rgba(241, 88, 47, 1)',
                data: dataSets[0],
                ...chartDatasetsOptions
            });
        }
        return dataToReturn;
    } catch (error) {
        return {};
    }
}

export const generatePageNumbers = (table) => {
    const totalPages = table.getPageCount();
    const currentPage = table.getState().pagination.pageIndex + 1;
    const pageNumbers = [];

    if (totalPages <= 3) {
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
    } else {
        if (currentPage <= 3) {
            pageNumbers.push(1, 2, 3, '...', totalPages);
        } else if (currentPage > 3 && currentPage < totalPages - 2) {
            pageNumbers.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
        } else {
            pageNumbers.push(1, '...', totalPages - 2, totalPages - 1, totalPages);
        }
    }

    return pageNumbers;
};

export const formatDollarNumber = (value) => {
    try {
        if (!value) return '0.00';
        const number = Number(value);
        if (number) {
            return `$${number.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`;
        }
        return value;
    } catch (error) {
        return value;
    }
}

export const extractDate = (startDate, endDate) => {
    const offset = startDate.getTimezoneOffset();
    const localStartDate = new Date(startDate.getTime() - offset * 60000);
    const localEndDate = new Date(endDate.getTime() - offset * 60000);
    return {
        startDate: localStartDate.toISOString().split('T')[0],
        endDate: localEndDate.toISOString().split('T')[0]
    }
}

export const statusColorAndIcon = (status) => {
    let color = '#FF8B03', icon = ProcessIcon;
    if (status === 'COMPLETED') {
        color = '#18BA3C';
        icon = CheckMarkIcon;
    } else if (status === 'FAILED') {
        color = '#9D0000';
        icon = BanIcon;
    } else if (status === 'PENDING') {
        color = '#F1582F'
        icon = HourglassIcon;
    }

    return { color, icon }
}

export const usDateFormat = (date) => {
    if (!date) return '';
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
}

export const downloadBlobFile = (data, fileName) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

export const generateContractEndDate = (startDate, monthDuration) => {
    const date = new Date(startDate);
    date.setMonth(date.getMonth() + Number(monthDuration));

    // Format the date to MM/dd/YYYY
    const endDate = `${('0' + (date.getMonth() + 1)).slice(-2)}/${('0' + date.getDate()).slice(-2)}/${date.getFullYear()}`;

    return endDate;
}

export const extractStartDate = (startDate) => {
    const offset = startDate.getTimezoneOffset();
    const localStartDate = new Date(startDate.getTime() - offset * 60000);

    return localStartDate.toISOString().split('T')[0];
}

export const stringToDate = (stringDate) => {
    const date = new Date(stringDate);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
}