import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, Form } from 'react-bootstrap';
import { useEditProfileMutation } from '../api/profileApi';
import { editProfileFormValidation } from '../../../../utils/formValidation';
import ProfileDefaultImg from '../../../../assets/images/others/ProfileDefault.png';
import NoteIcon from '../../../../assets/icons/Note';
import TrashIcon from '../../../../assets/icons/Trash';
import { setUser } from '../../../admin/login/slices/loginSlice';

const MyInformation = ({ setShowLoader, setToastMessage }) => {
    const userInfoSlice = useSelector((state) => state.login.user);
    const userInfo = {
        name: userInfoSlice?.name,
        phone: userInfoSlice?.phone,
        email: userInfoSlice?.email
    }
    const [formData, setFormData] = useState(userInfo);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [validationError, setValidationError] = useState({});
    const [editProfileApi] = useEditProfileMutation();
    const dispatch = useDispatch();

    const handleFileSelect = (e) => {
        const file = Array.from(e.target.files)[0];
        if (file) {
            uploadFiles(file);
        }
        e.target.value = ''; // Clear the input
    };

    const handleBrowseClick = () => document.getElementById('fileInput').click();

    const uploadFiles = (file) => {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        if (!allowedTypes.includes(file.type)) {
            setToastMessage({
                message: "Only .jpeg, .jpg, and .png files are allowed",
                variant: "error"
            });
            return;
        }
        setSelectedFiles([file]);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        const validationResult = editProfileFormValidation({ ...formData, [name]: value });
        if (!validationResult.errorMessage?.[name] && validationError[name]) {
            setErrorMessage('');
            setValidationError({ ...validationError, [name]: "" });
            return;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setErrorMessage('');
            const validationResult = editProfileFormValidation(formData);
            if (validationResult.errorMessage) {
                setValidationError(validationResult.errorMessage);
                return;
            }

            setShowLoader(true);
            const multipartFormData = new FormData();
            multipartFormData.append('name', validationResult.data.name);
            multipartFormData.append('phone', validationResult.data.phone);
            if (selectedFiles?.length) {
                multipartFormData.append('avatar', selectedFiles[0]);
            }
            const updatedProfileData = await editProfileApi(multipartFormData).unwrap();

            if (updatedProfileData?.data) {
                // Update redux store data
                dispatch(setUser(updatedProfileData.data));
            }

            setToastMessage({
                message: "Profile information has been updated successfully",
                variant: "success"
            });
            setSelectedFiles([]);
        } catch (err) {
            setValidationError({});
            setErrorMessage(err?.data?.message || err?.data?.error || 'Failed to edit profile');
        }
        setShowLoader(false);
    };

    useEffect(() => {
        // Set the form data with the profile API data when the user refreshes the profile page.
        setFormData(userInfo);
    }, [userInfoSlice]);

    return (
        <>
            <div className="layout__subheader d-flex flex-column mb-3">
                <h3 className="layout__subheader--title fs-5 mb-0">My Information</h3>
            </div>
            <div className="layout__body">
                {errorMessage && <p className="error-message fs-6">{errorMessage}</p>}
                <Form className='form__myinfo'>
                    <Card className='d-flex flex-row align-items-center border-0 mb-3 p-0'>
                        <Card.Header className='position-relative p-0 border-0'>
                            <Card.Img
                                src={selectedFiles.length > 0
                                    ? URL.createObjectURL(selectedFiles[0])
                                    : `${process.env.REACT_APP_AVATAR_FILE_PATH}${userInfoSlice?.avatar}`}
                                    className='object-fit-cover'
                                onError={(e) => {
                                    e.target.onerror = null; // Prevents infinite loop if the fallback also fails
                                    e.target.src = ProfileDefaultImg;
                                }}
                            />
                            <span className='card-img--delete d-inline-flex justify-content-center align-items-center position-absolute bottom-0 end-0 rounded-circle m-1'>
                                <TrashIcon size={14} />
                            </span>
                        </Card.Header>
                        <Card.Body className='p-0'>
                            <Form.Control
                                id="fileInput"
                                type="file"
                                name='imagefile'
                                className='d-none'
                                accept="image/jpeg, image/png, image/jpg"
                                onChange={handleFileSelect}
                            />
                            <Button
                                variant="primary-outline"
                                className='ms-3'
                                onClick={handleBrowseClick}
                            >
                                Browse
                            </Button>
                            {
                                selectedFiles?.length > 0 &&
                                <p
                                    className='card-img--filename d-inline-flex border rounded-2 px-2 py-1 ms-3 mt-2 mb-0'>
                                    <figure className='mb-0'><NoteIcon size={16} /></figure>
                                    <span
                                        className='quaternary-text text-truncate fw-medium ms-1'>
                                        {selectedFiles[0]?.name}
                                    </span>
                                </p>
                            }
                        </Card.Body>
                    </Card>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="Enter name"
                            value={formData.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {validationError.name && <p className="error-message position-absolute">{validationError.name}</p>}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="phone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            name="phone"
                            placeholder="Enter phone number"
                            value={formData.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {validationError.phone && <p className="error-message position-absolute">{validationError.phone}</p>}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <p className='primary-text'>{formData.email}</p>
                    </Form.Group>
                    <div className='d-flex'>
                        <Button variant="primary-outline" className='me-2' onClick={() => {
                            setValidationError({});
                            setErrorMessage('');
                            setSelectedFiles([]);
                            setFormData(userInfo);
                        }}>
                            Cancel
                        </Button>
                        <Button variant="-primary" type='submit' onClick={handleSubmit}>
                            Save
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default MyInformation;