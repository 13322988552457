import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import Select from 'react-select';
import './CustomerLocationMapping.scss';
import { Button } from 'react-bootstrap';
import { SearchBox, ReactTable, Loader, ReactDatePicker, ToastNotification } from '../../../components';
import { useListCustomersMutation } from "../customers/api/customersApi";
import { useListUnmappedLocationMutation, useAssignLocationToCustomerMutation } from "./api/customerLocationMappingApi";
import { setWarningMessage } from "../login/slices/loginSlice";
import { extractStartDate } from "../../../utils/helpers";
import { customerLocationMappingValidation } from "../../../utils/formValidation";

const CustomerLocationMapping = () => {
    const [searchFilteredLocations, setSearchFilteredLocations] = useState([]);
    const [selectedRowsInputs, setSelectedRowsInputs] = useState({});
    const columns = useMemo(() => [
        {
            header: '',
            id: 'checkbox',
            cell: ({ row }) => {
                return (
                    <label className="checkbox">
                        <input
                            className="checkbox__input"
                            type="checkbox"
                            onChange={(e) => handleDataInputChange(row.original.id, 'checked', e.target.checked)}
                            checked={selectedRowsInputs[row.original.id]?.checked ? true : false}
                        />
                        <span className="checkbox__box"></span>
                    </label>
                )
            }
        },
        {
            header: 'Contract Start Date',
            cell: ({ row }) => {
                return (
                    <>
                        <div className="position-relative py-2">
                            <ReactDatePicker
                                lable=""
                                placeholder="Select Contract Start Date"
                                minDate={null}
                                dateState={{ selectedDate: selectedRowsInputs[row.original.id]?.contract_start_date, setSelectedDate: (date) => handleDataInputChange(row.original.id, 'contract_start_date', date) }}
                            />
                            {
                                selectedRowsInputs[row.original.id]?.checked &&
                                !selectedRowsInputs[row.original.id]?.contract_start_date &&
                                <p className="error-message position-absolute">Please select contract start date</p>
                            }
                        </div>
                    </>
                )
            }
        },
        {
            header: 'Contract Duration (Months)',
            cell: ({ row }) => {
                return (
                    <>
                        <div className="position-relative py-2">
                            <input
                                type="number"
                                className="form-control lh-1"
                                value={selectedRowsInputs[row.original.id]?.contract_duration}
                                onChange={(e) => handleDataInputChange(row.original.id, 'contract_duration', e.target.value)}
                            />
                            {
                                <p className="error-message position-absolute">{customerLocationMappingValidation(selectedRowsInputs[row.original.id])}</p>
                            }
                        </div>
                    </>
                )
            }
        },
        { header: 'LOCATION NAME', accessorKey: 'name' },
        { header: 'CODE', accessorKey: 'code' },
        { header: 'PLACE OF ISSUE', accessorKey: 'place_of_issue' },
    ], [searchFilteredLocations, selectedRowsInputs]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customersList, setCustomersList] = useState([]);
    const [allUnmappedLocations, setAllUnmappedLocations] = useState([]);
    const [searchLocation, setSearchLocation] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [toastMessage, setToastMessage] = useState({});
    const [listCustomersApi] = useListCustomersMutation();
    const [listUnmappedLocationApi] = useListUnmappedLocationMutation();
    const [assignLocationApi] = useAssignLocationToCustomerMutation();
    const dispatch = useDispatch();

    const handleDataInputChange = (id, key, value) => {
        setSelectedRowsInputs((prev) => ({
            ...prev,
            [id]: {
                ...prev[id],
                [key]: value
            }
        }));
    }

    const handleAssignLocation = async () => {
        try {
            const dataToProcess = [];
            for (const i in selectedRowsInputs) {
                const { checked, contract_start_date, contract_duration } = selectedRowsInputs[i];
                if (checked && (!contract_start_date || !contract_duration)) {
                    setToastMessage({
                        message: "Some locations have been selected, but the contract information is missing. Please fill it in to proceed further.",
                        variant: "error"
                    });
                    return;
                }
                if (customerLocationMappingValidation({ checked, contract_duration })) {
                    setToastMessage({
                        message: "Please enter a valid contract duration for the selected location.",
                        variant: "error"
                    });
                    return;
                }

                if (checked && contract_start_date && contract_duration) {
                    dataToProcess.push({
                        id: i,
                        contract_start_date: extractStartDate(contract_start_date),
                        contract_duration
                    });
                }
            }

            if (selectedCustomer && dataToProcess?.length) {
                setShowLoader(true);
                await assignLocationApi({
                    customer_id: selectedCustomer,
                    locations: dataToProcess
                }).unwrap();
                setShowLoader(false);
                setSelectedCustomer(null);
                setSelectedRowsInputs({});
                await fetchUnmappedLocations();
                setSearchLocation('');
            }
        } catch (error) {
            setShowLoader(false);
        }
    }

    const fetchCustomers = async () => {
        try {
            const customersList = await listCustomersApi().unwrap();
            const customers = customersList?.data?.customers;
            if (customers?.length) {
                const customersArrList = customers.map(cp => ({ value: cp.id, label: `${cp.name} (${cp?.companies?.name})` }));
                customersArrList.sort((a, b) => a.label.localeCompare(b.label));
                setCustomersList(customersArrList);
            }
        } catch (error) { }
    };

    const fetchUnmappedLocations = async () => {
        try {
            setShowLoader(true);
            const unMappedLocations = await listUnmappedLocationApi().unwrap();
            if (unMappedLocations?.data) {
                if (unMappedLocations.data?.length > 0) {
                    dispatch(setWarningMessage({
                        warningMessage: {
                            unMappedLocations: unMappedLocations.data.length
                        }
                    }));
                } else if (unMappedLocations.data?.length === 0) {
                    dispatch(setWarningMessage({ warningMessage: {} }));
                }

                setAllUnmappedLocations(unMappedLocations.data);
                setSearchFilteredLocations(unMappedLocations.data);
            }
        } catch (error) { }
        setShowLoader(false);
    }

    useEffect(() => {
        if (searchLocation !== null) {
            const searchDebouncing = setTimeout(() => {
                if (!searchLocation) {
                    setSearchFilteredLocations(allUnmappedLocations);
                } else {
                    const filteredLocations = allUnmappedLocations.filter((l) => {
                        return l.name.toLowerCase().includes(searchLocation.toLowerCase());
                    })
                    setSearchFilteredLocations(filteredLocations);
                }

            }, 1000);

            return () => clearTimeout(searchDebouncing);
        }
    }, [searchLocation]);

    const applySearchFilter = (e) => setSearchLocation(e.target.value)

    useEffect(() => {
        fetchCustomers();
        fetchUnmappedLocations();
    }, []);

    return (
        <div className="content__wrapper content__wrapper--bgwhite content__wrapper--spacing customer-location-mapping-page position-relative">
            {/* Show Toast Message */}
            {toastMessage.message && <ToastNotification message={toastMessage.message} variant={toastMessage.variant} onClose={() => setToastMessage({})} />}

            {/* Show Loader */}
            {showLoader && <Loader />}

            <div className="layout__header mb-3">
                <h2 className="title mb-0">Customer Locations</h2>
            </div>
            <div className="row">
                <hr />
            </div>
            <div className="layout__subheader mb-3">
                <div className="d-flex flex-wrap align-items-center">
                    <div className="d-inline-flex flex-wrap flex-sm-nowrap align-items-sm-center me-2 my-1">
                        <span className='form-label text-nowrap me-2 mb-1 mb-sm-0'>Select Customer</span>
                        <Select
                            options={customersList}
                            value={customersList.find(customer => customer.value === selectedCustomer) || null}
                            onChange={(selectedOption) => {
                                setSelectedCustomer(selectedOption ? selectedOption.value : null);
                            }}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Select Customer"
                        />
                        <Button
                            variant="-primary"
                            className="mt-2 mt-sm-0 ms-sm-2"
                            disabled={selectedCustomer ? false : true}
                            onClick={handleAssignLocation}
                        >
                            Assign
                        </Button>
                    </div>
                    <SearchBox
                        placeholder="Search Location"
                        value={searchLocation}
                        handleChange={applySearchFilter}
                    />
                </div>
            </div>

            <div className="layout__body position-relative h-100">
                <ReactTable
                    data={searchFilteredLocations}
                    columns={columns}
                />
            </div>
        </div>
    );
};

export default CustomerLocationMapping;