import { useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Form } from 'react-bootstrap';
import CalendarBlankIcon from '../assets/icons/CalendarBlank'

const ReactRangeDatePicker = ({ lable, placeholder, dateRange, setDateRange }) => {
    const [startDate, endDate] = dateRange;
    const datePickerRef = useRef(null);
    const handleIconClick = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setFocus();
        }
    };

    return (
        <Form className='ca-datepicker'>
            <Form.Group className='d-flex align-items-center position-relative'>
                <Form.Label className='text-nowrap me-2 mb-0'>{lable}</Form.Label>
                <DatePicker
                    ref={datePickerRef}
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="MM/dd/YYYY"
                    className="form-control cursor-pointer"
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(update) => setDateRange(update)}
                    placeholderText={placeholder}
                    maxDate={new Date()}
                />
                <div className="icon cursor-pointer" onClick={handleIconClick}>
                    <CalendarBlankIcon size={20} />
                </div>
            </Form.Group>
        </Form>
    );
};

export default ReactRangeDatePicker;